<template>
  <div id="ProfileEdit">
    <div class="container my-2">
      <form>
        <b-form-group
          description="Nombres completos sin apellidos."
          class="mb-2"
        >
          <template #label><b>Nombres:</b></template>
          <b-form-input
            type="text"
            v-model="datos.name"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2">
          <template #label><b>Apellido Paterno:</b></template>
          <b-form-input
            type="text"
            v-model="datos.lastnameFather"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2">
          <template #label><b>Apellido Materno:</b></template>
          <b-form-input
            type="text"
            v-model="datos.lastnameMother"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          description="Fecha de nacimiento en la que naciste."
          class="mb-2"
        >
          <template #label><b>Fecha de nacimiento:</b></template>
          <b-form-input type="date" v-model="datos.birthDate"></b-form-input>
        </b-form-group>

        <b-form-group class="mb-2">
          <template #label><b>Género:</b></template>

          <b-form-select v-model="datos.genderId" :options="genders" />
        </b-form-group>
        <b-form-group
          description="Correo electrónico usado para ingresar al sistema y para poder comunicarnos contigo."
          class="mb-2"
        >
          <template #label><b>Correo electrónico:</b></template>
          <b-form-input
            type="text"
            v-model="datos.username"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-0 mt-3">
          <b-button @click="saveEditItem" variant="outline-primary"
            >Enviar</b-button
          >
        </b-form-group>
      </form>
    </div>
  </div>
</template>

<script>
import Alert from "../../components/AlertError.vue";
export default {
  components: {
    Alert,
  },
  name: "ProfileEdit",
  data() {
    return {
      datos: [],
      genders: [],
    };
  },
  created() {
    this.initialize();
    this.initializeGender();
  },
  methods: {
    async initialize() {
      await this.$axios
        .get("/profile/edit", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.datos = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async initializeGender() {
      await this.$axios
        .get("/person/gender", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.genders = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async saveEditItem() {
      await this.$axios
        .post("/profile/edit", this.datos, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.$message.success(res.data.content);
          this.$router.push("/profile");
        })
        .catch((error) => {
          this.$message.error(error.response.data.content);
        });
    },
  },
};
</script>