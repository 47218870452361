<template>
  <div id="CropperComponent">
    <center>
      <cropper
        class="cropper"
        ref="cropper"
        :src="img"
        :stencil-props="{
          aspectRatio: 10 / 10,
        }"
        @change="change"
      />
      <div class="align-center my-2">
        <b-avatar
          button
          variant="dark"
          title="Voltear horizontalmente"
          @click="flip(true, false)"
          icon="arrow-bar-right"
          class="mx-1"
        ></b-avatar>
        <b-avatar
          button
          variant="dark"
          title="Voltear verticalmente"
          @click="flip(false, true)"
          icon="arrow-bar-down"
          class="mx-1"
        ></b-avatar>
        <b-avatar
          button
          variant="dark"
          title="Rotar a la derecha"
          @click="rotate(90)"
          icon="arrow-clockwise"
          class="mx-1"
        ></b-avatar>
        <b-avatar
          button
          variant="dark"
          title="Rotar a la izquierda"
          @click="rotate(-90)"
          icon="arrow-counterclockwise"
          class="mx-1"
        ></b-avatar>
      </div>
      <b-img
        thumbnail
        fluid
        center
        class="mb-3"
        rounded="circle"
        v-bind="{ width: 250, height: 250 }"
        :src="image"
        alt="Image 1"
      ></b-img>
    </center>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "CropperComponent",
  components: {
    Cropper,
  },
  data() {
    return {
      img: "https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
      image: null,
    };
  },
  methods: {
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
      this.image = canvas.toDataURL();
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
  },
};
</script>
<style scoped>
.cropper {
  height: 500px;
  width: 500px;
  background: #ddd;
}
</style>