<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <!--<div>
      <horizontal />
    </div>-->
    <!--menu-->
    <div class="container">
      <!--Prueba-tabs-->
      <b-card no-body>
        <b-tabs style="background-color: #1d425e" lazy card vertical>
          <b-tab active>
            <template #title><b>Editar perfil</b></template>
            <ProfileEdit />
          </b-tab>
          <!--<b-tab>
            <template #title><b>Cambiar imagen</b></template>
            <div class="container my-2">
              <b-img
                thumbnail
                fluid
                center
                class="mb-3"
                rounded="circle"
                v-bind="{ width: 250, height: 250 }"
                src="https://picsum.photos/250/250/?image=54"
                alt="Image 1"
              ></b-img>
              <CropperComponent />
              <b-form-group class="mb-2">
                <template #label><b>Nueva imagen de perfil:</b></template>
                <b-input-group>
                  <b-form-file
                    browse-text="Buscar"
                    placeholder="Seleccionar imagen"
                  ></b-form-file>
                </b-input-group>
              </b-form-group>

              <b-form-group class="mb-0 mt-3">
                <b-button variant="outline-primary">Enviar</b-button>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab>
            <template #title><b>Cambiar contraseña</b></template>
            <div class="container my-2">
              <b-form-group class="mb-2">
                <template #label><b>Contraseña actual:</b></template>
                <b-input-group>
                  <b-form-input
                    type="password"
                    placeholder="Ingrese contraseña actual"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    <b-form-checkbox
                      switch
                      value="text"
                      unchecked-value="password"
                      class="mr-n2 mb-n1"
                    >
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <hr />
              <b-form-group
                description="Ingrese al menos 1 letra, al menos 1 número, al menos 1 letra mayúscula, al menos 6 caracteres, no se permite espacios."
                class="mb-2"
              >
                <template #label><b>Nueva contraseña:</b></template>
                <b-input-group>
                  <b-form-input
                    type="password"
                    placeholder="Ingrese nueva contraseña"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    <b-form-checkbox
                      switch
                      value="text"
                      unchecked-value="password"
                      class="mr-n2 mb-n1"
                    >
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="mb-2">
                <template #label><b>Verificar contraseña:</b></template>
                <b-input-group>
                  <b-form-input
                    type="password"
                    placeholder="Volver a ingresar nueva contraseña"
                  ></b-form-input>
                  <b-input-group-append is-text>
                    <b-form-checkbox
                      switch
                      value="text"
                      unchecked-value="password"
                      class="mr-n2 mb-n1"
                    >
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group class="mb-0 mt-3">
                <b-button variant="outline-primary">Enviar</b-button>
              </b-form-group>
            </div>
          </b-tab>
          -->
        </b-tabs>
      </b-card>
      <!---->
    </div>
  </div>
</template>
<script>
import CropperComponent from "../../components/Cropper.vue";
import ProfileEdit from "./Profile-edit-edit.vue";
export default {
  components: {
    CropperComponent,
    ProfileEdit,
  },
  data: () => ({
    selected: "",
  }),

  created() {},

  methods: {},
};
</script>